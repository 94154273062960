.user_view_btn{
    display: inline-flex;    
    justify-content: center;   
    align-items: center;
	border: none;   
    padding: 10px 15px; 
    border-radius: 5px;
	background-image: linear-gradient(to right, #54f354, #0ab80a, #0dc268);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8); 
    background-size: 200%;
    color: rgb(0, 0, 0);        
    text-decoration: none; 
    font-family: 'Poppins', sans-serif;
    margin: 0.6rem 0 0 0;
	cursor: pointer;
    transition: .5s;
}
.user_view_btn:hover{
    
	background-position: right;
}